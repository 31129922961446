import { useTranslation } from "react-i18next"
import AuthClientLayout from "../../../Components/Auth/Client/AuthClientLayout/AuthClientLayout"
import AuthModal from "../../../Components/Auth/Client/AuthModal/AuthModal"
import SocketProvider from "../../../Components/Common/SocketProvider"
import Layout from "../../../Components/UI/Layout/Layout"
import { LANGUAGES } from "../../../Utils/constants"
import classes from "./Languages.module.css"

const StaticLanguages = () => {
    const { t, i18n } = useTranslation()

    const handleLang = (lang) => {
        i18n.changeLanguage(lang)
    }

    return (
        <AuthClientLayout>
            {/* <AuthModal> */}
                <div className={classes.main}>
                    <h4>{t("languages.title")}</h4>
                    <div className={classes.langs}>
                        {LANGUAGES.map(el => (
                            <button
                                key={el.title}
                                onClick={() => handleLang(el.code)}
                            >
                                {el.title}
                            </button>
                        ))}
                    </div>
                </div>
            {/* </AuthModal> */}
        </AuthClientLayout>
    )
}

export default StaticLanguages