import { Route, Routes } from 'react-router-dom'
import ActionContainer from '../../Pages/Client/Action/ActionContainer'
import ForgotPassword from '../../Pages/Client/Auth/ForgotPassword'
import Login from '../../Pages/Client/Auth/Login'
import SignUp from '../../Pages/Client/Auth/SignUp'
import ChatContainer from '../../Pages/Client/Chat/ChatContainer'
import ChatRoomContainer from '../../Pages/Client/Chat/ChatRoomContainer'
import Client from '../../Pages/Client/Client'
import HomeContainer from '../../Pages/Client/Home/HomeContainer'
import Privacy from '../../Pages/Client/Info/Privacy'
import StaticPrivacy from '../../Pages/Client/Info/StaticPrivacy'
import StaticTerms from '../../Pages/Client/Info/StaticTerms'
import Terms from '../../Pages/Client/Info/Terms'
import Languages from '../../Pages/Client/Languages/Lagnuages'
import StaticLanguages from '../../Pages/Client/Languages/StaticLanguages'
import MyProfileContainer from '../../Pages/Client/MyProfile/MyProfileContainer'
import ProfileContainer from '../../Pages/Client/Profile/ProfileContainer'
import { ROUTES } from '../../Utils/constants'

const RoutesComponent = () => {
    return (
        <>
            <Routes>
                <Route path={ROUTES.client.signup} element={<SignUp/>}/>
                <Route path={ROUTES.client.login} element={<Login/>}/>
                <Route path={ROUTES.client.forgotPass} element={<ForgotPassword/>}/>
                <Route path='' element={<Client/>}>
                    <Route path={ROUTES.client.home} element={<HomeContainer/>}/>
                    <Route path={ROUTES.client.myProfile} element={<MyProfileContainer/>}/>
                    <Route path={`${ROUTES.client.profile}/:username`} element={<ProfileContainer/>}/>
                    <Route path={ROUTES.client.likes} element={<ActionContainer/>}/>
                    <Route path={ROUTES.client.visitors} element={<ActionContainer/>}/>
                    <Route path={ROUTES.client.favorites} element={<ActionContainer/>}/>
                    <Route path={ROUTES.client.chats} element={<ChatContainer/>}/>
                    <Route path={`${ROUTES.client.chats}/:id`} element={<ChatRoomContainer/>}/>
                    <Route path={ROUTES.client.languages} element={<Languages/>}/>
                    <Route path={ROUTES.client.terms} element={<Terms/>}/>
                    <Route path={ROUTES.client.privacyPolicy} element={<Privacy/>}/>
                </Route>
                <Route path={ROUTES.client.staticLanguages} element={<StaticLanguages/>}/>
                <Route path={ROUTES.client.staticTerms} element={<StaticTerms/>}/>
                <Route path={ROUTES.client.staticPrivacyPolicy} element={<StaticPrivacy/>}/>
            </Routes>
        </>
    )
}

export default RoutesComponent