import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import AuthClientLayout from '../../../Components/Auth/Client/AuthClientLayout/AuthClientLayout';
import AuthModal from '../../../Components/Auth/Client/AuthModal/AuthModal';
import classes from "./Styles.module.css";

const StaticPrivacy = () => {
    const { t } = useTranslation();

    return (
        <AuthClientLayout>
            <div className={classes.main}>
                <ReactMarkdown>{t("privacyPolicy")}</ReactMarkdown>
            </div>
        </AuthClientLayout>
    );
};

export default StaticPrivacy;
