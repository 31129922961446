import { useTranslation } from "react-i18next"
import SocketProvider from "../../../Components/Common/SocketProvider"
import Layout from "../../../Components/UI/Layout/Layout"
import { LANGUAGES } from "../../../Utils/constants"
import classes from "./Languages.module.css"

const Languages = () => {
    const { t, i18n } = useTranslation()

    const handleLang = (lang) => {
        i18n.changeLanguage(lang)
    }

    return (
        <SocketProvider>
            <Layout>
                <div className={classes.main}>
                    <h4>{t("languages.title")}</h4>
                    <div className={classes.langs}>
                        {LANGUAGES.map(el => (
                            <button
                                key={el.title}
                                onClick={() => handleLang(el.code)}
                            >
                                {el.title}
                            </button>
                        ))}
                    </div>
                </div>
            </Layout>
        </SocketProvider>
    )
}

export default Languages